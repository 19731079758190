import { styled } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { WalletDialog } from 'src/components/wallet-dialog/WalletDialog';
import { MainNavbar } from './navigation/MainNavbar';

const MainLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  // overflow: 'hidden',
  width: '100%'
}));

export const MainLayout = () => (
  <MainLayoutRoot>
    <MainNavbar>
      <Outlet />
      <WalletDialog />
    </MainNavbar>
  </MainLayoutRoot>
);
