import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  IconButton,
  Link,
  SwipeableDrawer,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import React, { useState } from 'react';
// import { Logo } from '../Logo';
import { NavRoutes } from './NavRoutes';

export const NavContent: React.FC = () => {
  const theme = useTheme();
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  const isMobile = useMediaQuery<boolean>(theme.breakpoints.down('md'));

  return (
    <>
      {/* {isMobile && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setMobileDrawerOpen(true)}
        >
          <MenuIcon />
        </IconButton>
      )} */}
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <Typography
          component={Link}
          variant="h2"
          color="textPrimary"
          href="https://axolittles.io/"
          underline="none"
          sx={{
            fontFamily: 'Winkle'
          }}
        >
          {/* <Logo className={classes.logo} /> */}
          AXOLITTLES
        </Typography>
      </Box>

      <SwipeableDrawer
        anchor="left"
        open={mobileDrawerOpen && isMobile}
        onClose={() => setMobileDrawerOpen(false)}
        onOpen={() => setMobileDrawerOpen(true)}
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            color="inherit"
            aria-label="close drawer"
            onClick={() => setMobileDrawerOpen(false)}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <NavRoutes onClick={() => setMobileDrawerOpen(false)} />
      </SwipeableDrawer>
    </>
  );
};
