import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import React from 'react';

interface IData {
  data: any;
  handleClick: (event: React.MouseEvent<unknown>, tokenId: string) => void;
  isSelected: (tokenId: string) => boolean;
}

export const NftCard: React.FC<IData> = ({ data, handleClick, isSelected }) => {
  const parsedMetadata = JSON.parse(data.metadata);

  const isItemSelected = isSelected(data.token_id);
  console.log(data);
  return (
    <Card
      sx={{ width: 265, margin: 'auto', cursor: 'pointer' }}
      onClick={(event) => handleClick(event, data.token_id)}
    >
      <img
        src={parsedMetadata?.image}
        draggable={false}
        style={{ width: '100%', height: '100%', pointerEvents: 'none' }}
      />

      <CardActions>
        <Checkbox
          checked={isItemSelected}
          onClick={(event) => handleClick(event, data.token_id)}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <Typography variant="body1" component="h4">
          {parsedMetadata?.title}
        </Typography>
      </CardActions>
    </Card>
  );
};
