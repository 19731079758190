import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useMoralis } from 'react-moralis';
import { NftContainer } from './NftContainer';

interface IProps {
  setApproval: () => void;
}

export const Staked: React.FC<IProps> = ({ setApproval }) => {
  const { Moralis, user } = useMoralis();
  const [stakedNfts, setStakedNfts] = useState<any>([]);

  const { enqueueSnackbar } = useSnackbar();

  const [selectedTokenIds, setSelectedTokenIds] = useState<readonly string[]>(
    []
  );

  const axolittlesContractAddress = process.env.REACT_APP_AXOLITTLES_CONTRACT!;
  const axolittlesStakingAddress = process.env.REACT_APP_STAKING_CONTRACT!;

  useEffect(() => {
    // Moralis.Web3API.account
    //   .getNFTTransfers({
    //     chain: 'eth',
    //     direction: 'to',
    //     address: axolittlesStakingAddress
    //   })
    //   .then((data: any) => {
    //     console.log('data', data);
    //   })
    //   .catch((err: any) => console.log('err', err));

    // Query for staked axolittles
    Moralis.Web3API.account
      .getNFTsForContract({
        chain: 'eth',
        address: axolittlesStakingAddress,
        token_address: axolittlesContractAddress,
        limit: 10
      })
      .then((data: any) => {
        const results = data.result;
        console.log(results);
        let stakedAxolittles: string[] = [];

        // if (results.length) {
        //   let validStakedTokens = results.filter(
        //     (nft: any) =>
        //       nft.from.toLowerCase() === user?.get('ethAddress').toLowerCase()
        //   );
        //   validStakedTokens.forEach((nft: any) => {
        //     stakedAxolittles = [...stakedAxolittles, nft.token_id];
        //   });
        // }
        setStakedNfts(results);
      })
      .catch((err) =>
        enqueueSnackbar(err.message ? err.message : err, { variant: 'error' })
      );
  }, []);

  const handleUnstake = async () => {
    if (!user) {
      enqueueSnackbar('User is not logged in', { variant: 'error' });
      return;
    }

    await setApproval();

    await (window as any).stakingContract.methods
      .unstake(selectedTokenIds.map((tokenId: string) => parseInt(tokenId, 10)))
      .send({
        from: user?.get('ethAddress')
      })
      .then((result: any) => {
        console.log(result);
        enqueueSnackbar('Unstake Successful!', { variant: 'success' });
      })
      .catch((err: any) => {
        console.log(err);
        enqueueSnackbar(
          `Unstake Unsuccessful: ${err.message ? err.message : err}`,
          {
            variant: 'error'
          }
        );
      });
  };

  const handleClick = (event: React.MouseEvent<unknown>, tokenId: string) => {
    const selectedIndex = selectedTokenIds.indexOf(tokenId);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTokenIds, tokenId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTokenIds.slice(1));
    } else if (selectedIndex === selectedTokenIds.length - 1) {
      newSelected = newSelected.concat(selectedTokenIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedTokenIds.slice(0, selectedIndex),
        selectedTokenIds.slice(selectedIndex + 1)
      );
    }

    setSelectedTokenIds(newSelected);
  };

  const isSelected = (tokenId: string) =>
    selectedTokenIds.indexOf(tokenId) !== -1;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = stakedNfts.map((nft: any) => nft.token_id);
      setSelectedTokenIds(newSelecteds);
      return;
    }
    setSelectedTokenIds([]);
  };

  return (
    <Accordion elevation={5}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="Staked-content"
      >
        <Typography>Staked</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justifyContent="space-between" sx={{ mb: 3 }}>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={
                    stakedNfts.length > 0 &&
                    selectedTokenIds.length === stakedNfts.length
                  }
                  onChange={handleSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all'
                  }}
                />
              }
              label="Select All"
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={selectedTokenIds.length === 0}
              onClick={handleUnstake}
            >
              Unstake {selectedTokenIds.length}
            </Button>
          </Grid>
        </Grid>

        <NftContainer
          handleClick={handleClick}
          isSelected={isSelected}
          nfts={stakedNfts}
        />
      </AccordionDetails>
    </Accordion>
  );
};
