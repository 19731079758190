import HomeIcon from '@mui/icons-material/Home';
import { List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { TractorIcon } from 'src/assets/icons/Tractor';

interface IRoutes {
  show: boolean;
  isExternal: boolean;
  path: string;
  name: string;
  icon: any;
}

interface INavRoutesProps {
  onClick?: any;
}

export const NavRoutes: React.FC<INavRoutesProps> = ({ onClick }) => {
  const routes = [
    {
      show: true,
      isExternal: false,
      path: '/',
      name: 'Home',
      icon: <HomeIcon />
    },
    {
      show: true,
      isExternal: false,
      path: '/farm',
      name: 'Farm',
      icon: <TractorIcon />
    }
  ] as IRoutes[];

  return (
    <List>
      {routes.map((route: IRoutes) => {
        return (
          route.show && (
            <ListItem
              key={route.name}
              component={NavLink}
              button
              to={route.path}
              sx={{ width: 250 }}
              onClick={onClick}
            >
              <ListItemIcon>{route.icon}</ListItemIcon>
              <ListItemText
                sx={{ color: 'text.primary' }}
                primary={route.name}
              />
            </ListItem>
          )
        );
      })}
    </List>
  );
};
