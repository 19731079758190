import LockIcon from '@mui/icons-material/Lock';
import { Button, Container, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { GlobalAppContext } from 'src/store/global-app-context';
import { ActionTypes } from 'src/store/reducer';

export const LockedPage = () => {
  const { dispatch } = useContext(GlobalAppContext);

  return (
    <Container
      maxWidth="sm"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <LockIcon
        sx={{
          color: 'error.main',
          fontSize: 54,
          mb: 3
        }}
      />
      <Typography
        color="textPrimary"
        variant="h2"
        sx={{ textAlign: 'center', mb: 3 }}
      >
        Connect a wallet to view this page
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          dispatch({
            type: ActionTypes.SET_SHOW_CONNECT_WALLET_DIALOG,
            payload: true
          });
        }}
      >
        Connect Wallet
      </Button>
    </Container>
  );
};
