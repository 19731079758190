import { useTheme } from '@mui/material/';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useMoralis } from 'react-moralis';
import AxolittlesAbi from 'src/assets/builds/axolittles.abi.json';
import StakingAbi from 'src/assets/builds/staking.abi.json';
import { GlobalAppContext } from 'src/store/global-app-context';
import { Staked } from './components/Staked';
import { Top } from './components/Top';
import { TotalEarned } from './components/TotalEarned';
import { Unstaked } from './components/Unstaked';

export const StakingLayout = () => {
  const { state } = useContext(GlobalAppContext);
  const theme = useTheme();
  const { Moralis, user } = useMoralis();
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const axolittlesContractAddress = process.env.REACT_APP_AXOLITTLES_CONTRACT!;

  const stakingContractAddress = process.env.REACT_APP_STAKING_CONTRACT!;

  useEffect(() => {
    const loadContractInstances = async () => {
      try {
        // Attaching the Moralis web3 object to window so it can be accessed throughout the site
        (window as any).web3 = await Moralis.Web3.enableWeb3();

        const axolittesContract = await new (window as any).web3.eth.Contract(
          AxolittlesAbi as any,
          axolittlesContractAddress
        );
        const stakingContract = await new (window as any).web3.eth.Contract(
          StakingAbi as any,
          stakingContractAddress
        );

        // Attaching the contracts to the window object so they can be accessed throughout the site
        (window as any).axolittesContract = axolittesContract;
        (window as any).stakingContract = stakingContract;

        const isApprovedResponse = await axolittesContract.methods
          .isApprovedForAll(user?.get('ethAddress'), stakingContractAddress)
          .call();

        setIsApproved(isApprovedResponse);
        console.log('isApproved ', isApprovedResponse);

        enqueueSnackbar('Connected', { variant: 'success' });
      } catch (err: any) {
        enqueueSnackbar(`12: ${err.message ? err.message : err}`, {
          variant: 'error'
        });
      }
    };

    loadContractInstances();
  }, []);

  const setApproval = async () => {
    if (isApproved) return;
    await (window as any).axolittesContract.methods
      .setApprovalForAll(stakingContractAddress, true)
      .send({
        from: user?.get('ethAddress')
      })
      .then((result: any) => {
        console.log(result);
        enqueueSnackbar('Approval Successful!', { variant: 'success' });
        setIsApproved(true);
      })
      .catch((err: any) => {
        console.log(err);
        enqueueSnackbar(
          `Approval Unsuccessful!: ${err.message ? err.message : err}`,
          {
            variant: 'error'
          }
        );
      });
  };

  return (
    <Container
      maxWidth={'lg'}
      style={{
        // margin: `${theme.spacing(3)}px auto`,
        height: '100%',
        paddingBottom: theme.spacing(3)
      }}
    >
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Top />
        </Grid>
        <Grid item xs={12} sm={12} md={4} sx={{ maxWidth: 'unset' }}>
          <TotalEarned />
        </Grid>
        <Grid item>
          <Staked setApproval={setApproval} />
        </Grid>
        <Grid item>
          <Unstaked setApproval={setApproval} />
        </Grid>
      </Grid>
    </Container>
  );
};
