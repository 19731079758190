import {
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useMoralis } from 'react-moralis';

export const TotalEarned = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery<boolean>(theme.breakpoints.down('md'));

  const { enqueueSnackbar } = useSnackbar();

  const { Moralis, user } = useMoralis();

  const handleClaimBubbles = async () => {
    if (!user) {
      enqueueSnackbar('User is not logged in', { variant: 'error' });
      return;
    }

    await (window as any).stakingContract.methods
      .claim([])
      .send({
        from: user?.get('ethAddress')
      })
      .then((result: any) => {
        console.log(result);
        enqueueSnackbar('Claim Successful!', { variant: 'success' });
      })
      .catch((err: any) => {
        console.log(err);
        enqueueSnackbar(
          `Claim Unsuccessful: ${err.message ? err.message : err}`,
          {
            variant: 'error'
          }
        );
      });
  };

  return (
    <Card elevation={5}>
      <CardContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography color="textPrimary" variant="h5">
                  Total $BUBBLES Earned
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  color="primary"
                  variant="h1"
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis'
                  }}
                >
                  XXX
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ ...(isMobile && { margin: 'auto' }) }}>
            <Grid container direction="column">
              <Grid item>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleClaimBubbles}
                >
                  Claim $BUBBLES
                </Button>
              </Grid>
              {/* <Grid item>
                <Button variant="contained" fullWidth>
                  Claim $BUBBLES and Unstake
                </Button>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
