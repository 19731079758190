import { Typography, useTheme } from '@mui/material';
import React from 'react';
import { Bubbles } from './Bubbles';

export const Top = () => {
  const theme = useTheme();
  return (
    <>
      <Typography
        component="h2"
        variant="h3"
        color="textPrimary"
        textAlign="center"
        sx={{ fontWeight: 500, mb: 5, mt: 5 }}
      >
        Stake your Axolittles to earn $BUBBLES!
      </Typography>
      <Bubbles />
    </>
  );
};
