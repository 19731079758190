import Grid from '@mui/material/Grid';
import React from 'react';
import { NftCard } from './NftCard';

interface IData {
  nfts: any[];
  handleClick: (event: React.MouseEvent<unknown>, tokenId: string) => void;
  isSelected: (tokenId: string) => boolean;
}

export const NftContainer: React.FC<IData> = ({
  nfts,
  handleClick,
  isSelected
}) => {
  return (
    <Grid container justifyContent="flex-start" spacing={2}>
      {nfts.map((nft: any, idx: number) => (
        <Grid item key={idx}>
          <NftCard
            data={nft}
            handleClick={handleClick}
            isSelected={isSelected}
          />
        </Grid>
      ))}
    </Grid>
  );
};
