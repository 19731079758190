import CloseIcon from '@mui/icons-material/Close';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LaunchIcon from '@mui/icons-material/Launch';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useMoralis } from 'react-moralis';
import { MetaMaskIcon } from 'src/assets/icons/MetaMask/MetaMask';
import { GlobalAppContext } from 'src/store/global-app-context';
import { ActionTypes } from 'src/store/reducer';

export const WalletDialog = () => {
  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();
  const { state, dispatch } = useContext(GlobalAppContext);

  const [connectWalletOpen, setConnectWalletOpen] = useState(false);
  const [walletAlreadyConnected, setWalletAlreadyConnected] = useState(false);

  const { authenticate, user, logout, Moralis } = useMoralis();

  useEffect(() => {
    if (state.showConnectWalletDialog && !user) {
      setConnectWalletOpen(true);
      setWalletAlreadyConnected(false);
    } else if (state.showConnectWalletDialog && user) {
      setConnectWalletOpen(false);
      setWalletAlreadyConnected(true);
    }
  }, [state.showConnectWalletDialog, user]);

  const connect = async () => {
    if (!user) {
      await authenticate().catch((err: any) => {
        console.log(err);
        enqueueSnackbar(`Error connecting wallet! ${err}`, {
          variant: 'success'
        });
      });
    }
  };

  const handleClose = () => {
    dispatch({
      type: ActionTypes.SET_SHOW_CONNECT_WALLET_DIALOG,
      payload: false
    });

    setWalletAlreadyConnected(false);
    setConnectWalletOpen(false);
  };

  const logoutHandler = () => {
    logout().then(() =>
      enqueueSnackbar('Successfully logged out!', { variant: 'success' })
    );
  };

  const copyAddress = () => {
    const copyText = document.getElementById('wallet-address') as any;

    if (copyText) {
      /* Select the text field */
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand('copy');

      /* Alert the copied text */
      enqueueSnackbar('Copied!', { variant: 'success' });
    }
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth="xs"
        open={connectWalletOpen}
        onClose={handleClose}
        aria-labelledby="connect-wallet"
      >
        <DialogTitle
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: theme.typography.h3.fontSize
          }}
        >
          <Typography variant="h4" color="textPrimary" component="p">
            Connect a Wallet
          </Typography>
          <IconButton aria-label="close" onClick={handleClose} color="primary">
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            onClick={connect}
            sx={{ justifyContent: 'space-between', textTransform: 'none' }}
            endIcon={<MetaMaskIcon />}
          >
            MetaMask
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={walletAlreadyConnected}
        onClose={handleClose}
        aria-labelledby="wallet-connected"
        sx={{ margin: 0, width: '100%' }}
      >
        <DialogTitle
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            fontSize: theme.typography.h3.fontSize
          }}
        >
          <Typography variant="h4" color="textPrimary" component="p">
            Your wallet
          </Typography>
          <IconButton aria-label="close" onClick={handleClose} color="primary">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {user && (
          <>
            <DialogContent dividers>
              <input
                value={user.get('ethAddress')}
                style={{
                  all: 'unset',
                  width: '100%',
                  color: theme.palette.text.primary,
                  ...theme.typography.body1,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
                readOnly
                id="wallet-address"
              />
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginTop: theme.spacing(2)
                }}
              >
                <Button
                  color="primary"
                  href={`https://bscscan.com/address/${user.get('ethAddress')}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  endIcon={<LaunchIcon />}
                  variant="outlined"
                >
                  View on BscScan
                </Button>
                <Button
                  onClick={copyAddress}
                  color="primary"
                  variant="outlined"
                  endIcon={<FileCopyIcon />}
                  style={{ marginLeft: theme.spacing(3) }}
                >
                  Copy Address
                </Button>
              </Box>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <Button
                onClick={logoutHandler}
                color="primary"
                variant="outlined"
                endIcon={<ExitToAppIcon />}
              >
                Logout
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
};
