import { Navigate } from 'react-router-dom';
import { MainLayout } from 'src/components/MainLayout';
import { Staking } from 'src/pages';

export const routes = [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: '/', element: <Staking /> },
      { path: '*', element: <Navigate to="/" /> }
    ]
  }
];
