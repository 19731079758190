import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useMoralis } from 'react-moralis';
import { NftContainer } from './NftContainer';

interface IProps {
  setApproval: () => void;
}

export const Unstaked: React.FC<IProps> = ({ setApproval }) => {
  const { Moralis, user } = useMoralis();
  const [unstakedNfts, setUnstakedNfts] = useState<any>([]);

  const { enqueueSnackbar } = useSnackbar();

  const [selectedTokenIds, setSelectedTokenIds] = useState<readonly string[]>(
    []
  );

  const axolittlesContractAddress = process.env.REACT_APP_AXOLITTLES_CONTRACT!;

  useEffect(() => {
    // Query for unstaked axolittles (axolittles in your account)
    Moralis.Web3API.account
      .getNFTsForContract({
        chain: 'eth',
        address: user?.get('ethAddress'),
        token_address: axolittlesContractAddress
      })
      .then((data: any) => {
        const results = data.result;
        console.log(results);
        setUnstakedNfts(results);
      })
      .catch((err) =>
        enqueueSnackbar(err.message ? err.message : err, { variant: 'error' })
      );
  }, []);

  useEffect(() => {
    console.log('selectedTokenIds ', selectedTokenIds);
  }, [selectedTokenIds]);

  const handleStake = async () => {
    if (!user) {
      enqueueSnackbar('User is not logged in', { variant: 'error' });
      return;
    }

    await setApproval();

    await (window as any).stakingContract.methods
      .stake(selectedTokenIds.map((tokenId: string) => parseInt(tokenId, 10)))
      .send({
        from: user?.get('ethAddress')
      })
      .then((result: any) => {
        console.log(result);
        enqueueSnackbar('Stake Successful!', { variant: 'success' });
      })
      .catch((err: any) => {
        console.log(err);
        enqueueSnackbar(
          `Stake Unsuccessful: ${err.message ? err.message : err}`,
          {
            variant: 'error'
          }
        );
      });
  };

  const handleClick = (event: React.MouseEvent<unknown>, tokenId: string) => {
    const selectedIndex = selectedTokenIds.indexOf(tokenId);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedTokenIds, tokenId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedTokenIds.slice(1));
    } else if (selectedIndex === selectedTokenIds.length - 1) {
      newSelected = newSelected.concat(selectedTokenIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedTokenIds.slice(0, selectedIndex),
        selectedTokenIds.slice(selectedIndex + 1)
      );
    }

    setSelectedTokenIds(newSelected);
  };

  const isSelected = (tokenId: string) =>
    selectedTokenIds.indexOf(tokenId) !== -1;

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = unstakedNfts.map((nft: any) => nft.token_id);
      setSelectedTokenIds(newSelecteds);
      return;
    }
    setSelectedTokenIds([]);
  };

  return (
    <Accordion elevation={5}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="Unstaked-content"
      >
        <Typography>Unstaked</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container justifyContent="space-between" sx={{ mb: 3 }}>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={
                    unstakedNfts.length > 0 &&
                    selectedTokenIds.length === unstakedNfts.length
                  }
                  onChange={handleSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all'
                  }}
                />
              }
              label="Select All"
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={selectedTokenIds.length === 0}
              onClick={handleStake}
            >
              Stake {selectedTokenIds.length}
            </Button>
          </Grid>
        </Grid>
        <NftContainer
          handleClick={handleClick}
          isSelected={isSelected}
          nfts={unstakedNfts}
        />
      </AccordionDetails>
    </Accordion>
  );
};
